import React from 'react';
import styled from 'styled-components';
import { em } from 'polished';
import { graphql, useStaticQuery } from 'gatsby';
import { renderAst } from '../../common/utils/markdown';

const StyledNotes = styled.section`
  display: grid;
  grid-gap: ${em(10)};
  
  ul {
    margin: 8px 0;
  }

  h2 {
    color: var(--secondaryLight-color);
    margin: 10px 0 5px 0;
  }

  h3 {
    color: var(--primaryLight-color);
    margin: 10px 0 5px 0;
  }

  strong {
    margin-bottom: ${em(10)};
  }
`;

interface Props {
  htmlAst: string;
}

export default function Notes(props: Props) {
  const { htmlAst } = props;
  return (
    <StyledNotes>
      {renderAst(htmlAst)}
    </StyledNotes>
  );
}
