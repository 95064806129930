import React from 'react';
import { Helmet } from 'react-helmet';

export default function Wowhead() {
  return (
    <div>
      <Helmet>
        <script src="https://wow.zamimg.com/widgets/power.js" async />
      </Helmet>
    </div>
  );
}
