import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Wowhead from '../client/common/components/Wowhead';
import SEO from '../client/common/layout/SEO';
import Top from '../client/names/components/Top';
import Layout from '../client/common/layout/Layout';
import Notes from '../client/flightmaster/components/Notes';

export default function Bis({ location }) {
  const data = useStaticQuery(graphql`
      query BisQuery {
          markdownRemark(fileAbsolutePath: { regex: "/bis.md/" }) {
              htmlAst
          }
      }
  `);
  const { markdownRemark: { htmlAst } } = data;

  return (
    <Layout mainClass="page-padding-height">
      <SEO
        title="MyBIS | WoW Classic"
        description=""
        location={location}
        noIndex
      />
      <Wowhead />
      <Top
        className="page-container page-padding-width"
        title="BIS Gear"
      />
      <hr />
      <div className="page-container page-padding-width">
        <Notes htmlAst={htmlAst} />
      </div>
    </Layout>
  );
}
