/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

interface Props {
  data: string;
  name: string;
}

export default function WowheadTooltip(props: Props) {
  const { data, name } = props;
  return (
    <a rel="noopener noreferrer" href={`https://classic.wowhead.com/${data}`}>{name}</a>
  );
}
